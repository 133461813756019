<template>
  <div class="proofService">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="proofTitle">上传服务凭证</div>
    <div class="proofContent">
      <div class="contentTitle">服务结果概述</div>
      <textarea
        v-model="params.contents[0].cnt"
        class="inputProof"
        maxlength="100"
        placeholder="请输入问题描述"
      ></textarea>
    </div>
    <!-- 有模板上传 -->
    <div v-if="params.templateCode">
      <div
        class="template-upload"
        v-for="item in params.templetInfo"
        :key="item.templateId"
      >
        <span class="title">{{ item.templateName }}</span>
        <div
          class="template-list"
          v-for="items in item.itemList"
          :key="items.templateId"
        >
          <span class="text"
            ><span style="color: red" v-if="items.isRequired == 2">* </span
            >{{ items.typeName }}</span
          >
          <div class="template-img-list">
            <div
              class="imgs"
              v-for="(itemImg, index) in items.imageList"
              :key="index"
            >
              <img
                class="delete-img"
                src="../assets/images/pending/delete.png"
                mode=""
                @click="onDeleteImages(index, items.imageList)"
              />
              <img
                class="img"
                :src="itemImg"
                mode=""
                @click="bigImg(items.imageList, index)"
              />
            </div>
            <div
              class="img-pop"
              @click="onAddimgs(items.imageList, items.id, items.maximum)"
              v-if="items.maximum > items.imageList.length"
            >
              <img
                src="../assets/images/pending/addImage.png"
                mode=""
                class="add-img"
              />
              <!-- <span>上传图片</span> -->
            </div>
            <div
              class="imgs"
              v-for="(itemUrlImg, index) in items.imgUrlList"
              :key="index"
            >
              <img
                class="img"
                :src="itemUrlImg"
                mode=""
                @click="bigImg(items.imgUrlList, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 无模板上传 -->
    <div class="on-template" v-else>
      <span class="on-text">上传图片</span>
      <div class="template-img-list">
        <div
          class="imgs"
          v-for="(item, index) in params.contents[0].urls"
          :key="index"
        >
          <img
            class="delete-img"
            src="../assets/images/pending/delete.png"
            mode=""
            @click="onDeleteImages(index, params.contents[0].urls)"
          />
          <img
            class="img"
            :src="item"
            mode=""
            @click="bigImg(params.contents[0].urls, index)"
          />
        </div>
        <div
          class="img-pop"
          @click="onAddimgs(params.contents[0].urls, null, 9)"
        >
          <img
            src="../assets/images/pending/addImage.png"
            mode=""
            class="add-img"
          />
          <!-- <span>上传图片</span> -->
        </div>
      </div>
    </div>
    <div class="btn">
      <van-button
        color="linear-gradient(to right, #f59b05, #FF7000)"
        :disabled="isDisabled"
        @click="onSubmit"
      >
        确认提交
      </van-button>
    </div>
  </div>
</template>
<script>
import { queryServiceTemplateInfo, saveServiceInfo } from "@/api/home.js";
import { ImagePreview, Toast } from "vant";
import { Button } from "@/utils/vant";
import { getNewCalls } from "../utils/upload";
export default {
  data() {
    return {
      params: {
        contents: [
          {
            cnt: "",
            urls: [],
          },
        ],
        orderId: this.$route.query.orderNo,
        orderRepairId: "",
        templateCode: "",
        templetInfo: [],
        trackCode: "10009",
      },
      imgId: "",
    };
  },
  created() {
    sessionStorage.setItem("accessToken", this.$route.query.accessToken);
  },
  mounted() {
    this.getQueryServiceTemplateInfo();
    window.getUpdateImageUrl_call = (res) => {
      this.getUpdateImageUrl_call(res);
    };
  },
  computed: {
    isDisabled() {
      return !this.params.contents[0].cnt;
    },
  },
  methods: {
    getQueryServiceTemplateInfo() {
      queryServiceTemplateInfo({ orderId: this.params.orderId }).then((res) => {
        if (res.success) {
          let data = res.data;
          this.params.templateCode = data.templateCode;
          if (data.templateCode) {
            //有模板
            if (data.orderRepairId) {
              //修改
              this.params.contents = data.contents;
              this.params.templetInfo = data.templetInfo;
              this.params.orderRepairId = data.orderRepairId;
            } else {
              this.params.templetInfo = data.templetInfo;
            }
          } else {
            //无模板
            if (data.orderRepairId) {
              //修改
              this.params.contents = data.contents;
              this.params.orderRepairId = data.orderRepairId;
            }
          }
        }
      });
    },
    onAddimgs(list, id, maximum) {
      this.imgId = id;
      getNewCalls(list, maximum);
    },
    //图片上传后的回调
    getUpdateImageUrl_call(res) {
      let imgs = JSON.parse(res);
      if (this.params.templateCode) {
        this.params.templetInfo.forEach((e) => {
          e.itemList.forEach((i) => {
            if (i.id == this.imgId) {
              imgs.map((o) => {
                i.imageList.push(o.filePath);
              });
            }
          });
        });
      } else {
        imgs.forEach((o) => {
          this.params.contents[0].urls.push(o.filePath);
        });
      }
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    onDeleteImages(data, datas) {
      datas.splice(data, 1);
    },
    //提交数据
    onSubmit() {
      let bool = true;
      if (this.params.templetInfo.length) {
        this.params.templetInfo.forEach((e) => {
          e.itemList.forEach((o) => {
            if (o.isRequired == 2) {
              if (o.imageList.length == 0) {
                bool = false;
                this.showTips(`"${o.typeName}"为必须上传的凭证`);
              }
            }
          });
        });
      }
      if (!bool) {
        return false;
      }
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "加载中...",
        forbidClick: true,
      });
      saveServiceInfo(this.params).then((res) => {
        Toast.clear();
        if (res.success) {
          window.history.go(-1);
          return false;
        } else {
          Toast(res.message);
        }
      });
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.proofService {
  .proofTitle {
    font-size: 54px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 54px;
    margin: 25px 0 0 29px;
  }
  .goBack {
    width: 750px;
    height: 76px;
    background: #ffffff;

    .goBackClose {
      display: inline-block;
      width: 31px;
      height: 31px;
      margin: 23px 0 0 25px;
      background: url("../assets/images/proof/goBackClose.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }
  .proofContent {
    margin: 50px 0 0 29px;

    .contentTitle {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }
    .inputProof {
      width: 690px;
      height: 248px;
      padding: 20px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      resize: none;
      outline: none;
      box-sizing: border-box;
      font-size: 26px;
    }
  }
  .title {
    font-size: 54px;
    font-weight: 600;
    color: #111111;
  }

  .text {
    padding: 40px 0;
    font-size: 28px;
    color: #666666;
    line-height: 30px;
  }

  .text span {
    padding: 0 10px;
    color: #ffbf00;
    font-size: 40px;
  }

  .on-template {
    margin: 0 29px;

    .on-text {
      font-size: 24px;
      font-weight: 600;
      color: #111111;
    }
  }

  .template-img-list {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .imgs {
      width: 110px;
      height: 110px;
      margin: 8px;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
      }

      .delete-img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }

    .img-pop {
      width: 110px;
      height: 110px;
      background: #f5f5f5;
      font-size: 18px;
      text-align: center;
      color: #666666;

      .add-img {
        width: 50px;
        height: 80px;
        display: block;
        margin: 10px auto 0;
      }
    }
  }

  .template-upload {
    margin: 0 29px;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #111111;
    }

    .template-list {
      .text {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .btn {
    margin: 50px 29px;
    button {
      width: 100%;
    }
  }
}
</style>